import React, {useEffect, useState, useContext} from 'react';
import {Link, NavLink} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {FirebaseContext} from '../firebase/firebaseindex';
import axios from 'axios';

import {FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon} from "react-share";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from 'simple-react-lightbox'

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


import c1 from '../imagenes/c1.png';
import c2 from '../imagenes/c2.png';
import c3 from '../imagenes/c3.png';
import c4 from '../imagenes/c4.png';
import c5 from '../imagenes/c5.png';
import c6 from '../imagenes/c6.png';
import c7 from '../imagenes/c7.png';
import c8 from '../imagenes/c8.png';
import c9 from '../imagenes/c9.png';
import c10 from '../imagenes/c10.png';
import c11 from '../imagenes/c11.png';
import c12 from '../imagenes/c12.png';
import c13 from '../imagenes/c13.png';
import c14 from '../imagenes/c14.png';
import c15 from '../imagenes/c15.png';
import c16 from '../imagenes/c16.png';
import b_icono from '../imagenes/back_icono.png';

const Detalle = () => {
  
  const [articulo, setArticulo] = useState([]);
  const {firebase} = useContext(FirebaseContext);

  
  //
  const navigate = useNavigate();

  const [cargarIconos, setCargarIconos] =  useState(false);


  const u = window.location.pathname;
  var res = u.split("/",);
  var res2 = res[2];
  var res3 = u.substr(9);
  //console.log(res3)


  useEffect(() => {
    window.scrollTo(0, 0);
    setData();
    asignarIcono(); 
  }, [cargarIconos]);


  //CARGAR PRODUCTO
  const setData = () => {
    var docx = firebase.db
        .collection('productos').doc(res3);
        docx.get().then(doc => {
          const dat = doc.data();
          //console.log(dat)
          setArticulo(dat)
          setCargarIconos(true)
          //console.log(articulo);
      }); 
  }

  function eliminar(){
    firebase.db.collection('productos').doc(res3).delete()
    .then(resp => navigate('/Productos'))
    .catch(e => console.log('error', e));
  }

  const alertar = () => {
    confirmAlert({
      title: 'Confirmar',
      message: '¿Quieres eliminar este producto?',
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: 'ELIMINAR',
          onClick: () => (eliminar())
        },
        {
          label: 'CANCELAR',
          onClick: () => alert('Operación cancelada')
        }
      ]
    });
  };

  //
  let currentUrl = "https://n8terra.com/Detalle/" + res3;
  //console.log(currentUrl)
  let title = articulo.nombre;
  let image = articulo.imagen;
  let description = articulo.desc;

  const asignarIcono = () => {
    const a1 = articulo.c_cold
    const a2 = articulo.c_hot
    const a3 = articulo.c_HotCold
    const a4 = articulo.c_micro
    const a5 = articulo.c_refri
    const a6 = articulo.c_ogm
    const a7 = articulo.c_bpi
    const a8 = articulo.c_comp
    const a9 = articulo.c_comp2
    const a10 = articulo.c_rec
    const a11 = articulo.c_ingeo
    const a12 = articulo.c_vegetal
    const a13 = articulo.c_fibra
    const a14 = articulo.c_madera
    const a15 = articulo.c_reuso
    const a16 = articulo.c_semilla
    if (a1){
      document.getElementById('c1').style.display = "block";
    }
    if (a2){
      document.getElementById('c2').style.display = "block";
    }
    if (a3){
      document.getElementById('c3').style.display = "block";
    }
    if (a4){
      document.getElementById('c4').style.display = "block";
    }
    if (a5){
      document.getElementById('c5').style.display = "block";
    }
    if (a6){
      document.getElementById('c6').style.display = "block";
    }
    if (a7){
      document.getElementById('c7').style.display = "block";
    }
    if (a8){
      document.getElementById('c8').style.display = "block";
    }
    if (a9){
      document.getElementById('c9').style.display = "block";
    }
    if (a10){
      document.getElementById('c10').style.display = "block";
    }
    if (a11){
      document.getElementById('c11').style.display = "block";
    }
    if (a12){
      document.getElementById('c12').style.display = "block";
    }
    if (a13){
      document.getElementById('c13').style.display = "block";
    }
    if (a14){
      document.getElementById('c14').style.display = "block";
    }
    if (a15){
      document.getElementById('c15').style.display = "block";
    }
    if (a16){
      document.getElementById('c16').style.display = "block";
    }
  }

  const options = {
   
    buttons: {
      showDownloadButton:false,
    },

    caption: {
      showCaption: false,
    }
   
  }

  return (
   
   
   <div className="main2">

    
      <Helmet>
        <meta charset="utf-8"/>
		    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{title}</title>
        <meta name="title" content={title}/>
        <meta name="description" content={description}/>
        <link rel='canonical' href='https://n8terra.com'/>
        <meta property="og:url" content={currentUrl} />
        <meta property="og:title" content={title} />
        <meta property='og:description' content={description}/>
		    <meta property="og:image" content={image}/>
        <meta property="og:type" content="website" />
      </Helmet>

     <div className="area_detalle">
        <div className="backBox">
          <Link to="/Productos"  className="linkL"><img src={b_icono} alt="images"/>VER CATÁLOGO</Link>
        </div>

        <div className="tituloBox2">
          <h1 className="home_titulo">DETALLE</h1>
        </div>
        
        <div>
          <h2 className="detalle_nombre2">{articulo.nombre}</h2>
          <p className="detalle_clave2"><span>ID:</span> {articulo.clave}</p>
        </div>  

        <div className="detalle_im">
          <SimpleReactLightbox> 
            <SRLWrapper options = {options}>
              <img src={articulo.imagen} alt="Producto"/>
              {
              articulo.imagen2
              ?
              <img src={articulo.imagen2} alt="Producto"/>
              :
              null
              }
              
              {
              articulo.imagen3
              ?
              <img src={articulo.imagen3} alt="Producto"/>
              :
              null
              }
              
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>
        <div className="detalle_info">
          <div className="detalle_main">
            <p className="detalle_clave"><span>ID:</span> {articulo.clave}</p>
            <h2 className="detalle_nombre">{articulo.nombre}</h2>
            <Link to="/Contacto" className="cotizarBtn">COTIZAR</Link>
          </div>
          <div className="detalle_extra">
            <div className="detalle_box">
              <p className="detalle_box_t">MATERIAL</p>
              <p className="detalle_box_p">{articulo.material}</p>
            </div>
            <div className="detalle_box">
              <p className="detalle_box_t">TAMAÑO</p>
              <p className="detalle_box_p">{articulo.medida}</p>
            </div>
            <div className="detalle_box">
              <p className="detalle_box_t">PIEZAS POR CAJA</p>
              <p className="detalle_box_p">{articulo.piezasCaja}</p>
            </div>
            <div className="detalle_box">
              <p className="detalle_box_t">DESCRIPCIÓN</p>
              <p className="detalle_box_p">{articulo.desc}</p>
            </div>
          </div>

          <p className="share_txt">Compartir:</p>
          <FacebookShareButton className="share_i"
                url={currentUrl}
                quote={title}
                hashtag="#N8Terra">
                <FacebookIcon size={36} />
          </FacebookShareButton>

          <WhatsappShareButton className="share_i"
                url={currentUrl}
                title="N8 Terra">
                <WhatsappIcon size={36} />
          </WhatsappShareButton>
          
          <div className="detalle_extras">
            <div id="c1" className="extras_box">
                <img src={c1} alt="Icono"/> <p>{articulo.c_cold}</p>
            </div>
            <div id="c2" className="extras_box">
                <img src={c2} alt="Icono"/> <p>{articulo.c_hot}</p>
            </div>
            <div id="c3" className="extras_box">
                <img src={c3} alt="Icono"/> <p>{articulo.c_HotCold}</p>
            </div>
            <div id="c4" className="extras_box">
                <img src={c4} alt="Icono"/> <p>{articulo.c_micro}</p>
            </div>
            <div id="c5" className="extras_box">
                <img src={c5} alt="Icono"/> <p>{articulo.c_refri}</p>
            </div>
            <div id="c6" className="extras_box">
                <img src={c6} alt="Icono"/> <p>{articulo.c_ogm}</p>
            </div>
            <div id="c7" className="extras_box">
                <img src={c7} alt="Icono"/> <p>{articulo.c_bpi}</p>
            </div>
            <div id="c8" className="extras_box">
                <img src={c8} alt="Icono"/> <p>{articulo.c_comp}</p>
            </div>
            <div id="c9" className="extras_box">
                <img src={c9} alt="Icono"/> <p>{articulo.c_comp2}</p>
            </div>
            <div id="c10" className="extras_box">
                <img src={c10} alt="Icono"/> <p>{articulo.c_rec}</p>
            </div>
            <div id="c11" className="extras_box">
                <img src={c11} alt="Icono"/> <p>{articulo.c_ingeo}</p>
            </div>
            <div id="c12" className="extras_box">
                <img src={c12} alt="Icono"/> <p>{articulo.c_vegetal}</p>
            </div>
            <div id="c13" className="extras_box">
                <img src={c13} alt="Icono"/> <p>{articulo.c_fibra}</p>
            </div>
            <div id="c14" className="extras_box">
                <img src={c14} alt="Icono"/> <p>{articulo.c_madera}</p>
            </div>
            <div id="c15" className="extras_box">
                <img src={c15} alt="Icono"/> <p>{articulo.c_reuso}</p>
            </div>
            <div id="c16" className="extras_box">
                <img src={c16} alt="Icono"/> <p>{articulo.c_semilla}</p>
            </div>
            <div className="clear"></div>
          </div>
        </div>
        <div className="clear"></div>
     </div>

   </div>

  );
}

export default Detalle;