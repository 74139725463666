import React from 'react';
import {NavLink, Link} from 'react-router-dom';

import cert1 from '../imagenes/cert1.png';
import cert2 from '../imagenes/cert2.png';
import cert3 from '../imagenes/cert3.png';
import tel from '../imagenes/tel.png';
import mail from '../imagenes/mail.png';

const Footer = () => {
  return (
   <div className="bottom">
     <div className="left_info">
       <p className="sub_bottom">Mapa del sitio</p>
       <Link to="/" className="link_bottom">Home</Link>
       <Link to="/Productos" className="link_bottom">Productos</Link>
       <Link to="/Blog" className="link_bottom">Blog</Link>
       <Link to="/Contacto" className="link_bottom">Contacto</Link>
     </div>
     <div className="left_info">
       <p className="sub_bottom">Contacto</p>
       <a className="link_bottom" href="mailto:helptheplanet@n8terra.com" target="_blank"><img src={mail} alt="Mail"/><p>helptheplanet@n8terra.com</p></a>
       <div className="clear"></div>
       <a className="link_bottom" href="tel:5579622215"><img src={tel} alt="Teléfono"/><p>+52 1 55.7962.2215</p></a>
     </div>
     <div className="logos_bottom">
        <img src={cert1} alt="FDA" />
        <img src={cert2} alt="Compostable" />
        <img src={cert3} alt="USDA" />
     </div>
     <div className="clear"></div>
     <p>© 2022 GRUPO N8 TERRA</p>
   </div>
  );
}

export default Footer;