import React, { useContext, useState, useEffect } from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import { FirebaseContext } from '../firebase/firebaseindex';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import hoja from '../imagenes/hoja_icono.png';
import tel from '../imagenes/tel.png';
import mail from '../imagenes/mail.png';

const Contacto = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  //
  const { firebase } = useContext(FirebaseContext);

  //
  var actualDay = ("0" + (new Date().getDate())).slice(-2)
	var actualMonth = ("0" + (new Date().getMonth() + 1)).slice(-2)
	var actualYear = new Date().getFullYear();
	var realDate = actualMonth + "/" + actualDay + "/" + actualYear;
  var date = realDate.toString();
  //console.log(date)

  //
  const navigate = useNavigate();

  //
  const formik = useFormik ({
      initialValues:{
          nombre: '',
          empresa: '',
          mail: '',
          tel: '',
          art: '',
          mensaje: '',
          fecha: date,
      },
      validationSchema: Yup.object({
            nombre: Yup.string()
                .required('Dato requerido'),
            mail: Yup.string()
                .required('Dato requerido'),
            tel: Yup.string()
                .required('Dato requerido'),
      }),
      onSubmit: () =>{
        try {
          handleFormSubmit();
          navigate('/');
        } catch (error) {
          console.log(error)
        }
      }
  })

  //ENVIAR MAIL
	const data = {
    Nombre: formik.values.nombre,
    Empresa: formik.values.empresa,
    Mail: formik.values.mail,
    Tel: formik.values.tel,
    Producto: formik.values.art,
    Mensaje: formik.values.mensaje,
    //mailSent: false,
    //error: null
  }

	const API_PATH = 'https://formspree.io/f/meqplzry';

	const handleFormSubmit = () => {
		axios({
		  method: 'post',
		  url: `${API_PATH}`,
		  headers: { 'content-type': 'application/json' },
		  data: data
		})
		  .then(result => {
       //console.log(result);
       alert("MENSAJE ENVIADO");
		  })
		  .catch(error => console.log( error ));
	};


  return (
   <div>
     <div className="area_detalle">
        <div className="tituloBox">
          <img src={hoja} alt="images"/>
          <h1 className="home_titulo">ENVÍANOS UN MENSAJE</h1>
          <div className="clear"></div>
        </div>
        <div className="area_agregar">
            <form onSubmit={formik.handleSubmit}>
                <div>
                    {formik.touched.nombre && formik.errors.nombre ? (
                      <p className="advertencia">El nombre es requerido</p>
                    ): null}
                    <label htmlFor="nombre">Nombre</label>
                    <input id="nombre"
                    type="text"
                    placeholder="Nombre"
                    value={formik.values.nombre}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    />
                </div>
                <div>
                    {formik.touched.nombre && formik.errors.nombre ? (
                      <p className="advertencia">El nombre es requerido</p>
                    ): null}
                    <label htmlFor="empresa">Empresa</label>
                    <input id="empresa"
                    type="text"
                    placeholder="Empresa"
                    value={formik.values.empresa}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    />
                </div>
                <div>
                    {formik.touched.mail && formik.errors.mail ? (
                      <p className="advertencia">El correo es requerido</p>
                    ): null}
                    <label htmlFor="mail">E-mail</label>
                    <input id="mail"
                    type="text"
                    placeholder="Correo electrónico"
                    value={formik.values.mail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    />
                </div>
                <div>
                    {formik.touched.tel && formik.errors.tel ? (
                      <p className="advertencia">El teléfono es requerido</p>
                    ): null}
                    <label htmlFor="tel">Teléfono</label>
                    <input id="tel"
                    type="number"
                    placeholder="Teléfono"
                    value={formik.values.tel}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    />
                </div>
                <div>
                    <label htmlFor="art">Artículo</label>
                    <input id="art"
                    type="text"
                    placeholder="Producto(s) de su interés"
                    value={formik.values.art}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    />
                </div>
                <div>
                    <label htmlFor="mensaje">Escriba un mensaje</label>
                    <textarea id="mensaje"
                    placeholder="Escriba un mensaje"
                    rows="10"
                    value={formik.values.mensaje}
                    onChange={formik.handleChange}
                    ></textarea>
                </div>
                <input type="submit" value="ENVIAR"/>
                <div className="clear"></div>  
            </form>
            <div className="mail_tel">
              <a href="tel:5579622215"><img src={tel} alt="Teléfono"/><p>+52 1 55.7962.2215</p></a>
              <a href="mailto:helptheplanet@n8terra.com" target="_blank"><img src={mail} alt="Mail" /><p>helptheplanet@n8terra.com</p></a>
              <div className="clear"></div>
            </div>
        </div>
      </div>  
   </div>
  );
}

export default Contacto;