import React from 'react';
import {Routes, Route } from 'react-router';

import { Helmet } from "react-helmet";

import firebase, { FirebaseContext } from './firebase/firebaseindex';


import Menu from './elementos/menu.js';
import Footer from './elementos/footer.js';
import Mensaje_WA from './elementos/mensajewa.js';

import Productos from './paginas/productos.js';
import Producto from './paginas/producto.js';

import Detalle from './paginas/detalle.js';

import Home from './paginas/home.js';

import Blog from './paginas/blog.js';
import Escrito from './paginas/escrito.js';

import EscritoTotal from './paginas/escritototal.js';

import Contacto from './paginas/contacto.js';

import './elementos/estilos.css';

function App() {
  return (
   
   <FirebaseContext.Provider
      value={{
        firebase
      }}
   >
    <div>
      <Helmet>
        <meta charset="utf-8"/>
		    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>N8 Terra</title>
        <meta name="title" content="N8 Terra"/>
        <meta name="description" content="Desechables Biodegradables con amplia variedad de soluciones para transportar comidas y bebidas desde tu negocio, restaurante, hotel, bar o desde casa."/>
        <meta property="og:url" content='https://n8terra.com/' />
        <meta property="og:title" content="N8 Terra" />
        <meta property='og:description' content='Desechables Biodegradables con amplia variedad de soluciones para transportar comidas y bebidas desde tu negocio, restaurante, hotel, bar o desde casa.'/>
		    <meta property="og:image" content='https://firebasestorage.googleapis.com/v0/b/n8terra01.appspot.com/o/portada%2Ffb_cover.jpg?alt=media&token=9ed47e01-94fd-4ca5-9999-c1f3c56d7842'/>
        <meta property="og:type" content="website" /> 
      </Helmet>
      <Menu/>
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route path="/Productos" element={<Productos/>}/>
        <Route path="/Producto" element={<Producto/>}/>

        <Route path="Detalle/:id" element={<Detalle/>}/>
        <Route path="EscritoTotal/:id" element={<EscritoTotal/>}/>
        
        <Route path="/Blog" element={<Blog/>}/>
        <Route path="/Escrito" element={<Escrito/>}/>

        <Route path="/Contacto" element={<Contacto/>}/>
      </Routes>
      <Mensaje_WA/>
      <Footer/>
    </div>
   </FirebaseContext.Provider>
  );
}

export default App;
