import React from 'react';
import {Link} from 'react-router-dom';


const Escrito = ({escrito}) => {
  
  return (
      <div className="escrito_min">
        <Link to={`/EscritoTotal/${ escrito.id }`}>
            <div className="escrito_margin"> 
              <div className="im_space2">
                  <img src={escrito.imagen} alt={escrito.titulo}/>
              </div>
              <div className="data_space2">
                <div className="info_space">
                    <p className="blog_fecha">{escrito.fecha}</p>
                    <h3 className="blog_titulo">{escrito.titulo}</h3>
                </div>
              </div>
              <div className="clear"></div>
            </div> 
        </Link>  
      </div>
  );
}

export default Escrito;