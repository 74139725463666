import React from 'react';
import {NavLink, Link} from 'react-router-dom';

import logo from '../imagenes/logo.png';
import facebookIcon from '../imagenes/facebook_icono.png';
import instagramIcon from '../imagenes/instagram_icono.png';
import twitterIcon from '../imagenes/twitter_icono.png';

const Menu = () => {
  
  return (
   <div>
      <div className="top">
        <div className="top_logo">
          <Link to="/"><img src={logo} alt="images"/></Link>
        </div>
        <nav className="top_menu">
            <NavLink className="linkTop" activeClassName="linkActive" to="/" end >HOME</NavLink>
            <NavLink className="linkTop" activeClassName="linkActive" to="/Productos">PRODUCTOS</NavLink>
            <NavLink className="linkTop" activeClassName="linkActive" to="/Blog">BLOG</NavLink>
            <NavLink className="linkTop" activeClassName="linkActive" to="/Contacto">CONTACTO</NavLink>
        </nav>
        <div className="top_redes">
            <a href="https://www.facebook.com/N8Terra/" target="_blank"><img src={facebookIcon} alt="Facebook"/></a>
            <a href="https://www.instagram.com/N8TERRA/" target="_blank"><img src={instagramIcon} alt="Instagram"/></a>
            <a href="https://twitter.com/n8terra" target="_blank"><img src={twitterIcon} alt="Twitter"/></a>
        </div>
      </div>

      <nav className="top_menuMin">
          <NavLink className="linkTop" activeClassName="linkActive" to="/" end >HOME</NavLink>
          <NavLink className="linkTop" activeClassName="linkActive" to="/Productos">PRODUCTOS</NavLink>
          <NavLink className="linkTop" activeClassName="linkActive" to="/Blog">BLOG</NavLink>
          <NavLink className="linkTop" activeClassName="linkActive" to="/Contacto">CONTACTO</NavLink>
      </nav>

   </div>

   
  );
}

export default Menu;