import React, {useState, useEffect, useContext} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {FirebaseContext} from '../firebase/firebaseindex';

import Producto from './producto';
import '../elementos/estilos.css';
import i0 from '../imagenes/cat0.png';
import i1 from '../imagenes/cat1.png';
import i2 from '../imagenes/cat2.png';
import i3 from '../imagenes/cat3.png';
import i4 from '../imagenes/cat4.png';
import i5 from '../imagenes/cat5.png';
import i6 from '../imagenes/cat6.png';
import i7 from '../imagenes/cat7.png';
import i8 from '../imagenes/cat8.png';
import i9 from '../imagenes/cat9.png';
import i10 from '../imagenes/cat10.png';
import i11 from '../imagenes/cat11.png';
import i12 from '../imagenes/cat12.png';
import i13 from '../imagenes/cat13.png';
import i14 from '../imagenes/cat14.png';
import i15 from '../imagenes/cat15.png';
import hoja from '../imagenes/hoja_icono.png';

const Productos = () => {
  
  const [articulos, setArticulos] = useState([]);
  const [articulosFiltro, setArticulosFiltro] = useState([]);
  const [eBuscar, setEBuscar] = useState('');
  const [subtitulo, setSubtitulo] = useState('Todos los productos')
  
  const {firebase} = useContext(FirebaseContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    getArticulo(); 
  }, []);
  
  const getArticulo =  async () => {
    const resultado = await firebase.db.collection('productos').get();
    const resultado2 = resultado.docs.map(doc =>{
      return {
        id: doc.id,
        ...doc.data()
      }
    });

    //const resultado1 = await firebase.db.collection('productos').where('nombre', '==', 'Vaso').get();
    //const resultado3 = resultado1.docs.map(doc =>{
     // return {
      //  id: doc.id,
      //  ...doc.data()
      //}
    //});

    //console.log(resultado2)
    setArticulos(resultado2)
    setArticulosFiltro(resultado2)
    //console.log(articulos)
  }

  function filtrar_select () {
    var op = document.getElementById("select_categoria").value;
    //
    if(op == "pla"){
      filtrarPla();
    }
    if(op == "papel"){
      filtrarPapel();
    }
    if(op == "trigo"){
      filtrarTrigo();
    }
    if(op == "fibra"){
      filtrarFibra();
    }
    if(op == "maiz"){
      filtrarMaiz();
    }
    if(op == "aguacate"){
      filtrarAguacate();
    }
    if(op == "madera"){
      filtrarMadera();
    }
    if(op == "kraft"){
      filtrarKraft();
    }
    //
    if(op == "caliente"){
      filtrarBebidas1();
    }
    if(op == "frias"){
      filtrarBebidas2();
    }
    if(op == "mesa"){
      filtrarMesa();
    }
    if(op == "food"){
      filtrarFood();
    }
    if(op == "bolsas"){
      filtrarBolsas();
    }
    if(op == "limpieza"){
      filtrarLimpieza();
    }
    if(op == "todos"){
      clear();
    }
  }

  //SELECTORES
  //
  function cambiarFiltro1 () {
    var x = document.querySelectorAll(".filtro_select");
    var i;
      for (i = 0; i < x.length; i++) {
          x[i].style.backgroundColor = "";
      }
  }

  function cambiarFiltro2 () {
    var x = document.querySelectorAll(".filtro_p");
    var i;
      for (i = 0; i < x.length; i++) {
          x[i].style.color = "";
      }
  }

  function clear () {
    setArticulosFiltro(articulos)
    setSubtitulo('Todos los productos')
    cambiarFiltro1();
    cambiarFiltro2();
    resetSelect();
    verTodo();
  }

  function verTodo () {
    document.getElementsByClassName('filtro_todo')[0].style.color = '';
  }

  function filtrarTodo () {
    document.getElementsByClassName('filtro_todo')[0].style.color = '#8c8c8c';
  }

  function resetSelect () {
    var s = document.getElementById("select_categoria");
    s.value = "todos";
  }

  function filtrarPla () {
    var sel = document.getElementsByClassName('filtro_select');
    var sel2 = document.getElementsByClassName('filtro_p');
    var s = document.getElementById("select_categoria");
    var mat =  articulos.filter(pla => {
      return pla.material == "PLA";
    });
    //console.log(mat)
    setArticulosFiltro(mat)
    setSubtitulo('PLA')

    filtrarTodo();
    cambiarFiltro1();
    cambiarFiltro2();
    sel[0].style.backgroundColor = "#007934";
    sel2[0].style.color = "#fff";
    s.value = "pla";
  }

   //
   function filtrarPapel () {
    var sel = document.getElementsByClassName('filtro_select');
    var sel2 = document.getElementsByClassName('filtro_p');
    var s = document.getElementById("select_categoria");
    var mat =  articulos.filter(papel => {
      return papel.material == "Papel + PLA";
    });
    //console.log(mat)
    setArticulosFiltro(mat)
    setSubtitulo('Papel + PLA')

    filtrarTodo();
    cambiarFiltro1();
    cambiarFiltro2();
    sel[1].style.backgroundColor = "#007934";
    sel2[1].style.color = "#fff";
    s.value = "papel";
  }

  //
  function filtrarTrigo () {
    var sel = document.getElementsByClassName('filtro_select');
    var sel2 = document.getElementsByClassName('filtro_p');
    var s = document.getElementById("select_categoria");
    var mat =  articulos.filter(trigo => {
      return trigo.material == "Paja de Trigo";
    });
    //console.log(mat)
    setArticulosFiltro(mat)
    setSubtitulo('Paja de Trigo')

    filtrarTodo();
    cambiarFiltro1();
    cambiarFiltro2();
    sel[2].style.backgroundColor = "#007934";
    sel2[2].style.color = "#fff";
    s.value = "trigo";
  }

  //
  function filtrarFibra () {
    var sel = document.getElementsByClassName('filtro_select');
    var sel2 = document.getElementsByClassName('filtro_p');
    var s = document.getElementById("select_categoria");
    var mat =  articulos.filter(fibra => {
      return fibra.material == "Fibra de Caña";
    });
    //console.log(mat)
    setArticulosFiltro(mat)
    setSubtitulo('Fibra de Caña')

    filtrarTodo();
    cambiarFiltro1();
    cambiarFiltro2();
    sel[3].style.backgroundColor = "#007934";
    sel2[3].style.color = "#fff";
    s.value = "fibra";
  }

   //
   function filtrarMaiz () {
    var sel = document.getElementsByClassName('filtro_select');
    var sel2 = document.getElementsByClassName('filtro_p');
    var s = document.getElementById("select_categoria");
    var mat =  articulos.filter(maiz => {
      return maiz.material == "Fécula de Maíz";
    });
    //console.log(mat)
    setArticulosFiltro(mat)
    setSubtitulo('Fécula de Maíz')

    filtrarTodo();
    cambiarFiltro1();
    cambiarFiltro2();
    sel[4].style.backgroundColor = "#007934";
    sel2[4].style.color = "#fff";
    s.value = "maiz";
  }

   //
   function filtrarAguacate () {
    var sel = document.getElementsByClassName('filtro_select');
    var sel2 = document.getElementsByClassName('filtro_p');
    var s = document.getElementById("select_categoria");
    var mat =  articulos.filter(aguacate => {
      return aguacate.material == "Semilla de Aguacate";
    });
    //console.log(mat)
    setArticulosFiltro(mat)
    setSubtitulo('Semilla de Aguacate')

    filtrarTodo();
    cambiarFiltro1();
    cambiarFiltro2();
    sel[5].style.backgroundColor = "#007934";
    sel2[5].style.color = "#fff";
    s.value = "aguacate";
  }

  //
  function filtrarMadera () {
    var sel = document.getElementsByClassName('filtro_select');
    var sel2 = document.getElementsByClassName('filtro_p');
    var s = document.getElementById("select_categoria");
    var mat =  articulos.filter(madera => {
      return madera.material == "Madera";
    });
    //console.log(mat)
    setArticulosFiltro(mat)
    setSubtitulo('Madera')

    filtrarTodo();
    cambiarFiltro1();
    cambiarFiltro2();
    sel[6].style.backgroundColor = "#007934";
    sel2[6].style.color = "#fff";
    s.value = "madera";
  }

  //
  function filtrarKraft () {
    var sel = document.getElementsByClassName('filtro_select');
    var sel2 = document.getElementsByClassName('filtro_p');
    var s = document.getElementById("select_categoria");
    var mat =  articulos.filter(kraft => {
      return kraft.material == "Kraft/Cartón";
    });
    //console.log(mat)
    setArticulosFiltro(mat)
    setSubtitulo('Kraft/Cartón')

    filtrarTodo();
    cambiarFiltro1();
    cambiarFiltro2();
    sel[7].style.backgroundColor = "#007934";
    sel2[7].style.color = "#fff";
    s.value = "kraft";
  }

   //
   function filtrarBebidas1 () {
    var sel = document.getElementsByClassName('filtro_select');
    var sel2 = document.getElementsByClassName('filtro_p');
    var s = document.getElementById("select_categoria");
    var fun =  articulos.filter(caliente => {
      return caliente.subfamilia == "Bebidas Calientes";
    });
    //console.log(fun)
    setArticulosFiltro(fun)
    setSubtitulo('Bebidas Calientes')

    filtrarTodo();
    cambiarFiltro1();
    cambiarFiltro2();
    sel[8].style.backgroundColor = "#007934";
    sel2[8].style.color = "#fff";
    s.value = "caliente";
  }

  //
  function filtrarBebidas2 () {
    var sel = document.getElementsByClassName('filtro_select');
    var sel2 = document.getElementsByClassName('filtro_p');
    var s = document.getElementById("select_categoria");
    var fun =  articulos.filter(frio => {
      return frio.subfamilia == "Bebidas Frías";
    });
    //console.log(fun)
    setArticulosFiltro(fun)
    setSubtitulo('Bebidas Frías')

    filtrarTodo();
    cambiarFiltro1();
    cambiarFiltro2();
    sel[9].style.backgroundColor = "#007934";
    sel2[9].style.color = "#fff";
    s.value = "frias";
  }

  //
  function filtrarMesa () {
    var sel = document.getElementsByClassName('filtro_select');
    var sel2 = document.getElementsByClassName('filtro_p');
    var s = document.getElementById("select_categoria");
    var fun =  articulos.filter(food => {
      return food.funcion == "Servicio de Mesa";
    });
    //console.log(fun)
    setArticulosFiltro(fun)
    setSubtitulo('Servicio de Mesa')

    filtrarTodo();
    cambiarFiltro1();
    cambiarFiltro2();
    sel[10].style.backgroundColor = "#007934";
    sel2[10].style.color = "#fff";
    s.value = "mesa";
  }

  //
  function filtrarFood () {
    var sel = document.getElementsByClassName('filtro_select');
    var sel2 = document.getElementsByClassName('filtro_p');
    var s = document.getElementById("select_categoria");
    var fun =  articulos.filter(food => {
      return food.funcion == "Food Service";
    });
    //console.log(fun)
    setArticulosFiltro(fun)
    setSubtitulo('Food Service')

    filtrarTodo();
    cambiarFiltro1();
    cambiarFiltro2();
    sel[11].style.backgroundColor = "#007934";
    sel2[11].style.color = "#fff";
    s.value = "food";
  }

  //
  function filtrarBolsas() {
    var sel = document.getElementsByClassName('filtro_select');
    var sel2 = document.getElementsByClassName('filtro_p');
    var s = document.getElementById("select_categoria");
    var fun =  articulos.filter(bolsas => {
      return bolsas.funcion == "Bolsas";
    });
    //console.log(fun)
    setArticulosFiltro(fun)
    setSubtitulo('Bolsas')

    filtrarTodo();
    cambiarFiltro1();
    cambiarFiltro2();
    sel[12].style.backgroundColor = "#007934";
    sel2[12].style.color = "#fff";
    s.value = "bolsas";
  }

  //
  function filtrarLimpieza() {
    var sel = document.getElementsByClassName('filtro_select');
    var sel2 = document.getElementsByClassName('filtro_p');
    var s = document.getElementById("select_categoria");
    var fun =  articulos.filter(limpieza => {
      return limpieza.funcion == "Limpieza";
    });
    //console.log(fun)
    setArticulosFiltro(fun)
    setSubtitulo('Limpieza')

    filtrarTodo();
    cambiarFiltro1();
    cambiarFiltro2();
    sel[13].style.backgroundColor = "#007934";
    sel2[13].style.color = "#fff";
    s.value = "limpieza";
  }

  function buscador () {
    var a = document.getElementById("buscar").value.toLowerCase();
    
      var nam =  articulos.filter(nam => {
        return nam.nombre.toLowerCase().includes(a) || nam.desc.toLowerCase().includes(a) || nam.clave.toLowerCase().includes(a) || nam.material.toLowerCase().includes(a) || nam.funcion.toLowerCase().includes(a) || nam.familia.toLowerCase().includes(a) || nam.subfamilia.toLowerCase().includes(a);
      });
      //console.log(nam)
      setArticulosFiltro(nam)
      setSubtitulo('Todos los productos')
      cambiarFiltro1();
      cambiarFiltro2();
      resetSelect();
      verTodo();

  }

  function buscador2 (){
    var a = document.getElementById("buscar").value.toLowerCase();
    setEBuscar(a);
    //console.log(eBuscar);
    buscador();
  }

  return (
    <div className="main2">

        <div className="barralateral">
          <div className="separador"></div>
          <div className="lateral">
              <div className="filtros_menu">
                <h3 className="filtros_titulo">CATEGORÍAS</h3>
                <a onClick={clear} className="filtro_todo">VER TODO</a>
              </div>    
              <div className="filtros_menu">
                <h4 className="filtros_sub">MATERIAL</h4>
                <a onClick={filtrarPla} className="filtro_select">
                  <img src={i1} className="cat_icono" alt="icono"/>
                  <p className="filtro_p">PLA</p>
                  <div className="clear"></div>
                </a>
                <a onClick={filtrarPapel} className="filtro_select">
                  <img src={i12} className="cat_icono" alt="icono"/>
                  <p className="filtro_p">Papel + PLA</p>
                  <div className="clear"></div>
                </a>
                <a onClick={filtrarTrigo} className="filtro_select">
                  <img src={i2} className="cat_icono" alt="icono"/>
                  <p className="filtro_p">Paja de Trigo</p>
                  <div className="clear"></div>
                </a>
                <a onClick={filtrarFibra} className="filtro_select">
                  <img src={i3} className="cat_icono" alt="icono"/>
                  <p className="filtro_p">Fibra de Caña</p>
                  <div className="clear"></div>
                </a>
                <a onClick={filtrarMaiz} className="filtro_select">
                  <img src={i4} className="cat_icono" alt="icono"/>
                  <p className="filtro_p">Fécula de Maíz</p>
                  <div className="clear"></div>
                </a>
                <a onClick={filtrarAguacate} className="filtro_select">
                  <img src={i5} className="cat_icono" alt="icono"/>
                  <p className="filtro_p">Semilla de Aguacate</p>
                  <div className="clear"></div>
                </a>
                <a onClick={filtrarMadera} className="filtro_select">
                  <img src={i14} className="cat_icono" alt="icono"/>
                  <p className="filtro_p">Madera</p>
                  <div className="clear"></div>
                </a>
                <a onClick={filtrarKraft} className="filtro_select">
                  <img src={i15} className="cat_icono" alt="icono"/>
                  <p className="filtro_p">Kraft/Cartón</p>
                  <div className="clear"></div>
                </a>
              </div>

              <div className="filtros_menu">
                <h4 className="filtros_sub">FUNCIÓN</h4>
                <a onClick={filtrarBebidas1} className="filtro_select">
                  <img src={i7} className="cat_icono" alt="icono"/>
                  <p className="filtro_p">Bebidas calientes</p>
                  <div className="clear"></div>
                </a>
                <a onClick={filtrarBebidas2} className="filtro_select">
                  <img src={i8} className="cat_icono" alt="icono"/>
                  <p className="filtro_p">Bebidas frías</p>
                  <div className="clear"></div>
                </a>
                <a onClick={filtrarMesa} className="filtro_select">
                  <img src={i13} className="cat_icono" alt="icono"/>
                  <p className="filtro_p">Servicio de Mesa</p>
                  <div className="clear"></div>
                </a>
                <a onClick={filtrarFood} className="filtro_select">
                  <img src={i9} className="cat_icono" alt="icono"/>
                  <p className="filtro_p">Food Service</p>
                  <div className="clear"></div>
                </a>
                <a onClick={filtrarBolsas} className="filtro_select">
                  <img src={i10} className="cat_icono" alt="icono"/>
                  <p className="filtro_p">Bolsas</p>
                  <div className="clear"></div>
                </a>
                <a onClick={filtrarLimpieza} className="filtro_select">
                  <img src={i11} className="cat_icono" alt="icono"/>
                  <p className="filtro_p">Limpieza</p>
                  <div className="clear"></div>
                </a>
              </div>
          </div>
        </div>

        <div className="contenido">
          <div className="contenido_buscar">
              <div className="barra_buscar">
                <input 
                  type="text"
                  id="buscar"
                  placeholder="Buscar..."
                  onChange={buscador2}/>
                  <button className="buscarBtn" onClick={buscador2}>BUSCAR</button>
              </div>

              <div className="barra_categoria">
                <select id="select_categoria" onChange={filtrar_select}>
                  <option value="todos">Filtrar por categoría</option>
                  <option value="pla">Material: PLA</option>
                  <option value="papel">Material: Fibras Recicladas</option>
                  <option value="trigo">Material: Paja de Trigo</option>
                  <option value="fibra">Material: Fibra de Caña</option>
                  <option value="maiz">Material: Fécula de Maíz</option>
                  <option value="aguacate">Material: Semilla de Aguacate</option>
                  <option value="madera">Material: Madera</option>
                  <option value="kraft">Material: Kraft/Cartón</option>

                  <option value="caliente">Función: Bebidas Calientes</option>
                  <option value="frias">Función: Bebidas Frías</option>
                  <option value="mesa">Función: Servicio de Mesa</option>
                  <option value="food">Función: Food Service</option>
                  <option value="bolsas">Función: Bolsas</option>
                  <option value="limpieza">Función: Limpieza</option>
                </select>
              </div>
          </div>

          <div className="contenido_central">
              <div className="tituloBox">
                <img src={hoja} alt="images"/>
                <h1 className="home_titulo">CATÁLOGO</h1>
                <div className="clear"></div>
                <a className="dldBtn" target="_blank" href="https://drive.google.com/file/d/1OErlml1uMTwx7hQbyln9Ph8AN3SUXJoT/view">DESCARGAR CATÁLOGO</a>
              </div>
              <h2 className="subtitulo">{subtitulo}</h2>
              <div id="area">
                {articulosFiltro.map(articulo => (
                  <Producto
                    key={articulo.id}
                    id={articulo.id}
                    articulo={articulo}
                  />
                ))}
                <div className="clear"></div>
              </div>
          </div>
        </div>

        <div className="clear"></div>


   </div>
  );
}

export default Productos;