import React, {useContext}  from 'react';
import {Link} from 'react-router-dom';
import {FirebaseContext} from '../firebase/firebaseindex';


const Producto = ({articulo}) => {
  
  const {firebase} = useContext(FirebaseContext);
  
  return (
   <div className="producto_min">
      <Link to={`/Detalle/${ articulo.id }`}>
          <div className="producto_margin"> 
            <div className="im_space">
                <img src={articulo.imagen} alt={articulo.nombre}/>
            </div>
            <div className="data_space">
              <div className="info_space">
                  <h3 className="producto_nombre">{articulo.nombre}</h3>
              </div>
              <div className="extra_space">
                <p className="producto_extra">Material: <span>{articulo.material}</span></p>
                <p className="producto_extra">Tamaño: <span>{articulo.medida}</span></p>
                <p className="producto_extra">Piezas: <span>{articulo.piezasCaja}</span></p>
                <p className="producto_extra">Clave: <span>{articulo.clave}</span></p>
              </div>
            </div>
          </div> 
      </Link> 
   </div>
  );
}

export default Producto;