import React, {useState, useEffect, useContext} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {FirebaseContext} from '../firebase/firebaseindex';

import Escrito from './escrito';
import '../elementos/estilos.css';

import hoja from '../imagenes/hoja_icono.png';


const Blog = () => {

  const [escritos, setEscritos] = useState([]);
  const {firebase} = useContext(FirebaseContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    getEscrito(); 
  }, []);
  
  const getEscrito =  async () => {
    const resultado = await firebase.db.collection('blog').orderBy('fecha', 'desc').get();
    const resultado2 = resultado.docs.map(doc =>{
      return {
        id: doc.id,
        ...doc.data()
      }
    });

    //console.log(resultado2)
    setEscritos(resultado2)
    //console.log(escritos)
  }


  return (
   <div>
      <div className="contenido_central">
            <div className="tituloBox">
              <img src={hoja} alt="images"/>
              <h1 className="home_titulo">BLOG</h1>
              <div className="clear"></div>
            </div>
              
          <div id="area">
            {escritos.map(escrito => (
              <Escrito
                key={escrito.id}
                id={escrito.id}
                escrito={escrito}
              />
            ))}
            <div className="clear"></div>
          </div>
      </div>
   </div>
  );
}

export default Blog;