import React, { useContext, useState, useEffect } from 'react';
import {NavLink, Link} from 'react-router-dom';
import {FirebaseContext} from '../firebase/firebaseindex';
import { Helmet } from "react-helmet";

import '../elementos/estilos.css';
import '../elementos/animate.css';

import Producto from './producto';
import icon1 from '../imagenes/mision_icono.png';
import icon2 from '../imagenes/vision_icono.png';
import hoja from '../imagenes/hoja_icono.png';
import logo2 from '../imagenes/logo2.png';
import close_icon from '../imagenes/close.png';


const Home = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const cover1 = 'https://firebasestorage.googleapis.com/v0/b/n8terra01.appspot.com/o/portada%2Fn8_video.mp4?alt=media&token=f7168bf1-199f-4802-a01e-f7d705dc17dc';
  const cover2 = 'https://firebasestorage.googleapis.com/v0/b/n8terra01.appspot.com/o/portada%2Fcover1.jpg?alt=media&token=3667fd02-ba29-4171-875d-babd7057e432';
  const cover3 = 'https://firebasestorage.googleapis.com/v0/b/n8terra01.appspot.com/o/portada%2Fcover2.jpg?alt=media&token=eb0bf2f4-382c-41f0-89ec-50d4d42dbb0c';

  const [articulos, setArticulos] = useState([]);

  const {firebase} = useContext(FirebaseContext);
  
  
  //
  const getArticulo1 =  async () => {
    const resultado1 = await firebase.db.collection('productos').where('material', '==', 'PLA').limit(4).get();
    const resultado2 = resultado1.docs.map(doc =>{
      return {
        id: doc.id,
        ...doc.data()
      }
    });
    setArticulos(resultado2)
  }

  const getArticulo2 =  async () => {
    const resultado1 = await firebase.db.collection('productos').where('material', '==', 'Paja de Trigo').limit(4).get();
    const resultado2 = resultado1.docs.map(doc =>{
      return {
        id: doc.id,
        ...doc.data()
      }
    });
    setArticulos(resultado2)
  }

  const getArticulo3 =  async () => {
    const resultado1 = await firebase.db.collection('productos').where('material', '==', 'Fibra de Caña').limit(4).get();
    const resultado2 = resultado1.docs.map(doc =>{
      return {
        id: doc.id,
        ...doc.data()
      }
    });
    setArticulos(resultado2)
  }

  const getArticulo4 =  async () => {
    const resultado1 = await firebase.db.collection('productos').where('material', '==', 'Fécula de Maíz').limit(4).get();
    const resultado2 = resultado1.docs.map(doc =>{
      return {
        id: doc.id,
        ...doc.data()
      }
    });
    setArticulos(resultado2)
  }

  const getArticulo5 =  async () => {
    const resultado1 = await firebase.db.collection('productos').where('material', '==', 'Semilla de Aguacate').limit(4).get();
    const resultado2 = resultado1.docs.map(doc =>{
      return {
        id: doc.id,
        ...doc.data()
      }
    });
    setArticulos(resultado2)
  }

  const getArticulo6 =  async () => {
    const resultado1 = await firebase.db.collection('productos').where('material', '==', 'Hoja de Palma Caída').limit(4).get();
    const resultado2 = resultado1.docs.map(doc =>{
      return {
        id: doc.id,
        ...doc.data()
      }
    });
    setArticulos(resultado2)
  }

  //
  function openPop1() {
    document.getElementById("pop1").style.display = "block";
    getArticulo1();
  }

  function openPop2() {
    document.getElementById("pop2").style.display = "block";
    getArticulo2();
  }

  function openPop3() {
    document.getElementById("pop3").style.display = "block";
    getArticulo3();
  }

  function openPop4() {
    document.getElementById("pop4").style.display = "block";
    getArticulo4();
  }

  function openPop5() {
    document.getElementById("pop5").style.display = "block";
    getArticulo5();
  }

  function openPop6() {
    document.getElementById("pop6").style.display = "block";
    getArticulo6();
  }

  function closePop(){
    var pop = document.getElementsByClassName('popup');
       var i;
        for (i = 0; i < pop.length; i++) {
             pop[i].style.display = "none";
        } 
  }

  function ccover1(){
    var b = document.getElementsByClassName("circuloBtn");
    document.getElementById("cover_im1").style.display = "block"; 
    document.getElementById("cover_im2").style.display = "none"; 
    document.getElementById("cover_im3").style.display = "none";  
    b[0].style.opacity = 1;
    b[1].style.opacity = "";
    b[2].style.opacity = ""; 
  }

  function ccover2(){
    var b = document.getElementsByClassName("circuloBtn");
    document.getElementById("cover_im2").style.display = "block"; 
    document.getElementById("cover_im1").style.display = "none"; 
    document.getElementById("cover_im3").style.display = "none"; 
    b[1].style.opacity = 1;
    b[0].style.opacity = "";
    b[2].style.opacity = "";   
  }

  function ccover3(){
    var b = document.getElementsByClassName("circuloBtn");
    document.getElementById("cover_im3").style.display = "block"; 
    document.getElementById("cover_im1").style.display = "none"; 
    document.getElementById("cover_im2").style.display = "none"; 
    b[2].style.opacity = 1;
    b[0].style.opacity = "";
    b[1].style.opacity = "";  
  }


  
  return (
    
    <div className="main">

      <Helmet>
        <meta charset="utf-8"/>
		    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>N8 Terra</title>
        <meta name="title" content="N8 Terra"/>
        <meta name="description" content="Desechables ecológicos."/>
        <link rel='canonical' href='https://n8terra.com'/>
        <meta property="og:url" content= 'https://n8terra.com' />
        <meta property="og:title" content= 'N8 Terra' />
        <meta property='og:description' content='Desechables ecológicos.'/>
		    <meta property="og:image" content='https://firebasestorage.googleapis.com/v0/b/n8terra01.appspot.com/o/portada%2Ffb_cover.jpg?alt=media&token=9ed47e01-94fd-4ca5-9999-c1f3c56d7842'/>
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="popup" id="pop1">
        <img className="close_pop" src={close_icon} alt="Cerrar" onClick={closePop}/>
          <div className="popup_material">
            <div className="popup_im" id="pop_im1"></div>
            <div className="popup_texto">
              <h4>PLA (Ácido Poliláctico)</h4>
              <p>Es un termoplástico cuyos materiales de base se obtienen a partir de almidón de maíz o de yuca o mandioca, o de caña de azúcar.</p>
            </div>
          </div>
          <div id="pop_area">
              {articulos.map(articulo => (
                <Producto
                  key={articulo.id}
                  id={articulo.id}
                  articulo={articulo}
                />
              ))}
          </div>
      </div>

      <div className="popup" id="pop2">
        <img className="close_pop" src={close_icon} alt="Cerrar" onClick={closePop}/>
          <div className="popup_material">
            <div className="popup_im" id="pop_im2"></div>
            <div className="popup_texto">
              <h4>Paja de Trigo</h4>
              <p>La paja de trigo es una fibra insoluble que se encuentra principalmente en la capa externa de este cereal. Las fibras de celulosa procedentes del mismo residuo son la clave parad dotar de la rigidez necesaria al material para su aplicación.</p>
            </div>
          </div>
          <div id="pop_area">
              {articulos.map(articulo => (
                <Producto
                  key={articulo.id}
                  id={articulo.id}
                  articulo={articulo}
                />
              ))}
          </div>
      </div>

      <div className="popup" id="pop3">
        <img className="close_pop" src={close_icon} alt="Cerrar" onClick={closePop}/>
          <div className="popup_material">
            <div className="popup_im" id="pop_im3"></div>
            <div className="popup_texto">
              <h4>Fibra de Caña</h4>
              <p>Es un material fibroso, heterogéneo en cuanto a su composición granulométrica y estructural, que presenta relativamente baja densidad y un alto contenido de humedad, en las condiciones en que se obtiene del proceso de molienda de la caña.</p>
            </div>
          </div>
          <div id="pop_area">
              {articulos.map(articulo => (
                <Producto
                  key={articulo.id}
                  id={articulo.id}
                  articulo={articulo}
                />
              ))}
          </div>
      </div>

      <div className="popup" id="pop4">
        <img className="close_pop" src={close_icon} alt="Cerrar" onClick={closePop}/>
          <div className="popup_material">
            <div className="popup_im" id="pop_im4"></div>
            <div className="popup_texto">
              <h4>Fécula de Maíz</h4>
              <p>El plástico biodegradable obtenido con materia prima como la fécula de maíz, también sirve para fabricar cubiertos, para que no tengas que usar los de plástico que tardan muchos años en degradarse y mejor optes por estos objetos para que los lleves a la oficina o simplemente los tengas en casa.</p>
            </div>
          </div>
          <div id="pop_area">
              {articulos.map(articulo => (
                <Producto
                  key={articulo.id}
                  id={articulo.id}
                  articulo={articulo}
                />
              ))}
          </div>
      </div>

      <div className="popup" id="pop5">
        <img className="close_pop" src={close_icon} alt="Cerrar" onClick={closePop}/>
          <div className="popup_material">
            <div className="popup_im" id="pop_im5"></div>
            <div className="popup_texto">
              <h4>Semilla de Aguacate</h4>
              <p>una resina termoplástica para la manufactura de productos de plástico biodegradable y compostable.</p>
            </div>
          </div>
          <div id="pop_area">
              {articulos.map(articulo => (
                <Producto
                  key={articulo.id}
                  id={articulo.id}
                  articulo={articulo}
                />
              ))}
          </div>
      </div>

      <div className="popup" id="pop6">
        <img className="close_pop" src={close_icon} alt="Cerrar" onClick={closePop}/>
          <div className="popup_material">
            <div className="popup_im" id="pop_im6"></div>
            <div className="popup_texto">
              <h4>Hoja de Palma Caída</h4>
              <p>Un producto de hoja de palmera está elaborado a partir de las hojas caídas de los árboles de las palmeras. Estos árboles se despojan de sus hojas de manera natural entre cuatro y siete veces al año. Después las hojas se recogen, se limpian con agua y se prensan en caliente con diferentes formas y tamaños. No se utilizan productos químicos ni recubrimientos en el proceso de elaboración de nuestros productos de hojas de palmeras.</p>
            </div>
          </div>
          <div id="pop_area">
              {articulos.map(articulo => (
                <Producto
                  key={articulo.id}
                  id={articulo.id}
                  articulo={articulo}
                />
              ))}
          </div>
      </div>

      <div className='promos'>
        <div className="max-area">
          <div className="promo_area">
              <div id="cover_im1" className="cover_im">
                <video loop={true} autoPlay={true} muted><source src={cover1} type="video/mp4"/></video> 
              </div>
              <div id="cover_im2" className="cover_im">
                <img src={cover2} alt="images"/>
              </div>
              <div id="cover_im3" className="cover_im">
                <img src={cover3} alt="images"/>
              </div>
              <div className="circulos">
                  <button className="circuloBtn" onClick={ccover1}>1</button>
                  <button className="circuloBtn" onClick={ccover2}>2</button>
                  <button className="circuloBtn" onClick={ccover3}>3</button>
              </div>
          </div>
          <NavLink to="/Productos" className="btnPortada">VER PRODUCTOS</NavLink>
        </div>  
      </div>
      
      <div className="portada">
        <div className="portadaBox">
          <img className="portada_im" src={logo2} alt="N8"/>
          <p className="portada_t">N8 Terra es una empresa 100% mexicana dedicada a la distribución de Desechables Biodegradables que ayuden a reducir el impacto a nuestro planeta. Nuestra filosofía está basada en el número 8, que es considerado como un símbolo infinito, reflejando la regeneración eterna como  representación de la conexión entre la tierra y la vida, que fluyen en movimiento permanente.</p> 
        </div>   
      </div>

      <div className="block back_gris">
        <div className="max_area">
            <div className="tituloBox">
              <img src={hoja} alt="images"/>
              <h1 className="home_titulo">TU BENEFICIO ES NUESTRO PUNTO DE PARTIDA</h1>
              <div className="clear"></div>
            </div>
            <div className="block1">
              <img src={icon1} alt="images"/>
              <div>
                <h3>MISIÓN</h3>
                <p>Promover un cambio positivo en el planeta a través de la utilización de productos y materiales biodegradables que ayuden a la sustentabilidad del planeta, buscando un equilibrio perfecto entre la vida y la tierra.</p>
              </div>
            </div> 

            <div className="block2">
              <img src={icon2} alt="images"/>
              <div>
                <h3>VISIÓN</h3>
                <p>Promover un cambio positivo en el planeta a través de la utilización de productos y materiales biodegradables que ayuden a la sustentabilidad del planeta, buscando un equilibrio perfecto entre la vida y la tierra.</p>
              </div>
            </div>
            <div className="clear"></div> 
        </div>              
      </div>

      <div className="block back_blanco">
        <div className="max_area">
            <div className="tituloBox">
              <img src={hoja} alt="images"/>
              <h1 className="home_titulo">MATERIALES</h1>
              <div className="clear"></div>
            </div>
            <div className="materiales_box">
              <div className="material_box" id="material_box1">
                <div className="material_mask"></div>
                <div className="material_boxT">
                  <p className="material_boxTP">PLA (Ácido Poliláctico)</p>
                  <button className="material_boxTB" onClick={openPop1}>DETALLES</button>
                </div>
              </div>
              <div className="material_box" id="material_box2">
                <div className="material_mask"></div>
                <div className="material_boxT">
                  <p className="material_boxTP">Paja de Trigo</p>
                  <button className="material_boxTB" onClick={openPop2}>DETALLES</button>
                </div>
              </div>
              <div className="material_box" id="material_box3">
                <div className="material_mask"></div>
                <div className="material_boxT">
                  <p className="material_boxTP">Fibra de Caña</p>
                  <button className="material_boxTB" onClick={openPop3}>DETALLES</button>
                </div>
              </div>
              <div className="material_box" id="material_box4">
                <div className="material_mask"></div>
                <div className="material_boxT">
                  <p className="material_boxTP">Fécula de Maíz</p>
                  <button className="material_boxTB" onClick={openPop4}>DETALLES</button>
                </div>
              </div>
              <div className="material_box" id="material_box5">
                <div className="material_mask"></div>
                <div className="material_boxT">
                  <p className="material_boxTP">Semilla de Aguacate</p>
                  <button className="material_boxTB" onClick={openPop5}>DETALLES</button>
                </div>
              </div>
              <div className="material_box" id="material_box6">
                <div className="material_mask"></div>
                <div className="material_boxT">
                  <p className="material_boxTP">Hoja de Palma Caída</p>
                  <button className="material_boxTB" onClick={openPop6}>DETALLES</button>
                </div>
              </div>
            </div>
            <div className="clear"></div> 
        </div>     
      </div>

    </div>
  );
}

export default Home;