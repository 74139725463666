import React from 'react';
import ReactWhatsapp from 'react-whatsapp';

import whats from '../imagenes/whatsapp_icono.png';

const Mensaje_WA = () => { 

return (
   <div className="abs_bottom">
     <ReactWhatsapp number="+52 1 5579622215" message="Hola. Me interesa un producto." element="none">
        <img src={whats} alt="WhatsApp" className="animated pulse infinite" />
     </ReactWhatsapp>
   </div>
  );
}

export default Mensaje_WA;