import React, {useEffect, useState, useContext} from 'react';
import {Link} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {FirebaseContext} from '../firebase/firebaseindex';
import axios from 'axios';
import { DiscussionEmbed } from 'disqus-react';

import {FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon} from "react-share";
import { Helmet } from "react-helmet";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


const EscritoTotal = () => {
  

  const [escrito, setEscrito] = useState([]);
  const {firebase} = useContext(FirebaseContext);
  
  //
  const navigate = useNavigate();

  const [re, setRe] = useState(false);

  const u = window.location.pathname;
  var res = u.split("/",);
  var res2 = res[2];
  var res3 = u.substr(14);
  //console.log(res3)


  useEffect(() => {
    window.scrollTo(0, 0);
    setData();
  }, [re]);
  

  //CARGAR ESCRITO
  const setData = () => {
    var docx = firebase.db
        .collection('blog').doc(res3);
        docx.get().then(doc => {
          const dat = doc.data();
          //console.log(dat);
          setEscrito(dat);
          setRe(true);
          //console.log(escrito);
      }); 
  }

  function eliminar(){
    firebase.db.collection('blog').doc(res3).delete()
    .then(resp => navigate('/Blog'))
    .catch(e => console.log('error', e));
  }

  const alertar = () => {
    confirmAlert({
      title: 'Confirmar',
      message: '¿Quieres eliminar esta entrada?' + res3,
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: 'ELIMINAR',
          onClick: () => (eliminar())
        },
        {
          label: 'CANCELAR',
          onClick: () => alert('Operación cancelada')
        }
      ]
    });
  };

  let currentUrl = "https://n8terra.com/EscritoTotal/" + res3;
  //console.log(currentUrl)
  let title = escrito.titulo;
  let image = escrito.imagen;
  let description = escrito.titulo;

  

  const disqusShortname = "n8terra-1"
  const disqusConfig = {
      url: "https://n8terra.com/EscritoTotal/" + res3,
      identifier: res3,
      title: escrito.titulo,
    }

  
  return (
   
    <div className="main2">

      <Helmet>
        <meta charset="utf-8"/>
		    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{title}</title>
        <meta name="title" content={title}/>
        <meta name="description" content={description}/>
        <link rel='canonical' href='https://n8terra.com'/>
        <meta property="og:url" content={currentUrl} />
        <meta property="og:title" content={title} />
        <meta property='og:description' content={description}/>
		    <meta property="og:image" content={image}/>
        <meta property="og:type" content="website" />
      </Helmet>


     <div className="area_detalle">
        <div className="tituloBox">
          <p>{escrito.fecha}</p>
          <h1 className="home_titulo3">{escrito.titulo}</h1>
          <div className="clear"></div>
        </div>
        <div className="detalle_im2">
          <img src={escrito.imagen} alt="Producto"/>
        </div>
        <div className="detalle_info2">
          <p style={{whiteSpace: "pre-wrap"}}>{escrito.texto}</p>
        </div>
        <div className="clear"></div>

        <div>
          <p className="share_txt">Compartir:</p>
          <FacebookShareButton className="share_i"
                url={currentUrl}
                quote={title}
                hashtag="#N8Terra">
                <FacebookIcon size={36} />
          </FacebookShareButton>
        </div>

        <div className="comentarios">
          <DiscussionEmbed
            shortname={disqusShortname}
            config={disqusConfig}
          />
        </div>
     </div>

   </div>
    
  
  );
}

export default EscritoTotal;