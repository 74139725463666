const firebaseConfig = {
  apiKey: "AIzaSyDpGoCXDG35TkGSOA3q0qXplFoyxz_kiX8",
  authDomain: "n8terra01.firebaseapp.com",
  databaseURL: "https://n8terra01.firebaseio.com",
  projectId: "n8terra01",
  storageBucket: "n8terra01.appspot.com",
  messagingSenderId: "1007666136261",
  appId: "1:1007666136261:web:3ff0583a8ea78518c35cac",
  measurementId: "G-B69G74XRBH"
  };

  export default firebaseConfig;